import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormContext } from "../../../../contexts/FormContext"; // Ensure correct import path
import { InputField, SelectField } from "../../../../components/forms/InputField/InputField"; // Ensure correct import path
import CheckBox from "../../../../components/Checkbox";
import classnames from "classnames";
import passEyeClose from "../../../../assets/passEyeClose.svg";
import passEyeOpen from "../../../../assets/passEyeOpen.svg";
import { ThankYouSignUp } from "../../../../modals";
import useFormValidation from "../../../../hooks/new/useFormValidation"; // Ensure correct import path
import "./SignUp.scss"; // Use the same SignUp.scss for consistent styling

const Step2 = ({ step }) => {
  const navigate = useNavigate();
  const { formData, updateField, clearFormData } = useFormContext();
  const { errors, validateField, clearErrors } = useFormValidation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [success, setSuccess] = useState();
  const [signupError, setSignupError] = useState();

  // Array for education options
  const educationOptions = [
    { value: 2, label: 'High School Diploma or GED' },
    { value: 5, label: `Associate's (or other 2-year degree)` },
    { value: 6, label: 'Bachelor’s Degree' },
    { value: 8, label: 'Master’s Degree' },
    { value: 11, label: 'Doctoral Degree' },
  ];
  // Load form data from local storage on component mount
  useEffect(() => {
    const savedFormData = localStorage.getItem("signupFormData");

    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);
      Object.keys(parsedData).forEach((key) => {
        updateField(key, parsedData[key]);
      });
    } else {
      navigate("/new/student"); // Redirect to Step 1 if no saved data is found
    }

    if (formData["is_read_terms_and_policies"] === undefined) {
      updateField("is_read_terms_and_policies", false);
    }
    if (
      formData["allow_data_sharing"] === undefined ||
      formData["allow_data_sharing"] === ""
    ) {
      updateField("allow_data_sharing", false);
    }
  }, []);

  const requiredFields = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "address",
    "city",
    "state",
    "zipcode",
    "age",
    "password",
    "confirm_password",
    // "certainty",
    // "favorite_ice_cream_flavor",
    // "careers",
    // "grade",
    // "allow_data_sharing",
    "education",
    "is_read_terms_and_policies",
  ];

  // Check if all fields are filled and there are no errors
  useEffect(() => {
    const allFieldsFilled = requiredFields.every(
      (field) =>
        formData[field] !== "" &&
        formData[field] !== undefined &&
        formData["is_read_terms_and_policies"]
    );

    // console.log("allFieldsFilled", allFieldsFilled);

    const hasErrors = Object.values(errors).some((error) => error);

    setIsFormComplete(allFieldsFilled && !hasErrors);
  }, [formData, errors]);

  const getErrorFromBackend = () => {
    return "An error occurred during sign-up. Please try again.";
  };

  const closeErrorModal = () => {
    updateField("isErrorModalOpened", false);
  };

  const handleGoBack = () => {
    navigate("/new/student");
  };

  // Define validateFields with useCallback to prevent it from causing re-renders
  const validateFields = useCallback(() => {
    // Include fields from Step 1 for validation
    const fieldsToValidate = requiredFields;

    fieldsToValidate.forEach((field) => validateField(field, formData[field]));
  }, [formData, validateField]);

  // Define handleSignUp before using it in handleSubmit
  const handleSignUp = useCallback(() => {
    console.log("Sign up initiated with form data:", formData);

    console.log("formData", formData);

    const allStudentData = {
      ...formData,
      role: "Student",
      education:parseFloat(formData.education)
      // address: `${formData.address}, ${formData.city}, ${formData.state}, ${formData.zipcode}`,
    };
    const affiliateCode = allStudentData?.affiliate_code
    if(!affiliateCode || affiliateCode.trim() === ''){
      delete allStudentData.affiliate_code
    }
    fetch(`${process.env.REACT_APP_API_URL}signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(allStudentData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((js_data) => {
            throw new Error(`${Object.values(js_data)[0]}`);
          });
        }
        return response.json();
      })
      .then((response) => {
        setSuccess(true);
        clearFormData(); // Clear form data after successful sign-up
      })
      .catch((error) => {
        console.error("Error during sign-up:", error);
        setSuccess(false);
        setSignupError(error);
      });
  }, [formData, navigate]);

  // Now use handleSignUp inside handleSubmit
  const handleSubmit = useCallback(() => {
    console.log("Form data before validation:", formData);
    validateFields();

    // Synchronously check for errors immediately after validation
    const hasErrors = Object.values(errors).some((error) => error);
    console.log("Validation errors found:", errors, "Has Errors:", hasErrors);

    if (hasErrors) {
      console.error("Validation errors found:", errors);
      navigate("/new/student"); // Navigate back to Step 1 if there are errors
    } else {
      clearErrors();
      updateField("role", "Student");
      // updateField(
      //   "address",
      //   `${formData.address}, ${formData.city}, ${formData.state}, ${formData["zipcode"]}`
      // );
      handleSignUp();
    }
  }, [errors, clearErrors, handleSignUp, validateFields, navigate]);
  return (
    <div className="signup">
      <div className="signup-container">
        <div className="signup-wrapper">
          <InputField
            label="Password*"
            field="password"
            type={showPassword ? "text" : "password"}
            value={formData.password || ""}
            onChange={(value) => {
              updateField("password", value);
            }}
            error={errors.password}
            icon={showPassword ? passEyeOpen : passEyeClose}
            onIconClick={() => setShowPassword((prev) => !prev)}
          />
          <InputField
            label="Confirm Password*"
            field="confirm_password"
            type={showConfirmPassword ? "text" : "password"}
            value={formData["confirm_password"] || ""}
            onChange={(value) => {
              updateField("confirm_password", value);
            }}
            error={errors.confirmPassword}
            icon={showConfirmPassword ? passEyeOpen : passEyeClose}
            onIconClick={() => setShowConfirmPassword((prev) => !prev)}
          />
        </div>
        <div className="password__error">
          {formData.password &&
            formData.confirm_password &&
            formData.password !== formData["confirm_password"] && (
              <div className="">Passwords do not match</div>
            )}
        </div>
        <div className="signup-wrapper">
          <InputField
            label="Favorite Ice Cream Flavor"
            field="favorite_ice_cream_flavor"
            value={formData["favorite_ice_cream_flavor"] || ""}
            onChange={(value) =>
              updateField("favorite_ice_cream_flavor", value)
            }
          />
        </div>
        <div className="signup-wrapper">
          <InputField
            label="What career(s) are you thinking about? (comma-separated)"
            field="careers"
            value={formData.careers || ""}
            onChange={(value) => updateField("careers", value)}
          />
        </div>
        <div className="signup-wrapper">
          <div className="signup-level">
            <p className="signup-level__title">
              Level of certainty of your future career path
            </p>
            <div className="signup-level__cont">
              {[...Array(10).keys()].map((i) => {
                const item = i + 1;
                return (
                  <div
                    onClick={() => {
                      updateField("certainty", item);
                    }}
                    key={item}
                    className="signup-level__item"
                  >
                    <div
                      className={classnames("signup-level__circle", {
                        "signup-level__circle--active":
                          formData.certainty === item,
                      })}
                    />
                    <p className="signup-level__number">{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="signup-wrapper">
          <SelectField
            label="What is the highest level of education you’re aiming to reach?*"
            field="grade"
            value={formData.education || ""}
            options={educationOptions}
            onChange={(value) => updateField("education", value)}
            error={errors.education}
          />
        </div>
        <div className="signup-wrapper">
          <InputField
            label="Affiliate Code"
            field="affiliate_code"
            value={formData["affiliate_code"] || ""}
            onChange={(value) => updateField("affiliate_code", value)}
          />
        </div>
        <div className="signup-wrapper">
          <CheckBox
            checked={formData["is_read_terms_and_policies"] || false}
            changeHandler={() =>
              updateField(
                "is_read_terms_and_policies",
                !formData["is_read_terms_and_policies"]
              )
            }
            id={"is_read_terms_and_policies"}
            className={
              formData["is_read_terms_and_policies"]
                ? ""
                : "signup-checkbox-error"
            }
          />
          <p className="signup-explanation">
            I certify that I am over the age of 13 and that I have read and
            agree to the{" "}
            <Link target="_blank" to="/privacypolicy" className="signup-link">
              Privacy Policy
            </Link>{" "}
            and the website’s{" "}
            <Link target="_blank" to="/terms" className="signup-link">
              Terms of Service.
            </Link>
            *
          </p>
        </div>
        <div className="signup-wrapper">
          <CheckBox
            checked={formData["allow_data_sharing"] || false} // Ensure the checkbox is always controlled
            changeHandler={() =>
              updateField("allow_data_sharing", !formData["allow_data_sharing"])
            }
            id={"allow_data_sharing"}
          />
          <p className="signup-explanation">
            I allow Career Scoops Inc. to share my data with third-party
            educational institutions as defined in the{" "}
            <Link target="_blank" to="/privacypolicy" className="signup-link">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>

      <div className="signup-navigation-wrapper">
        <button onClick={handleGoBack} className="signup-navigation__clear">
          Back
        </button>
        <button
          onClick={handleSubmit}
          disabled={!isFormComplete}
          className="signup-navigation__continue"
        >
          Sign Up
        </button>
      </div>
      {success && (
        <ThankYouSignUp
          isOpened={success}
          onClose={() => setSuccess()}
          title={"Sign up successful!"}
          confirmationText={
            "Confirm your email to proceed—check spam if it’s not in your inbox."
          }
        />
      )}
      {success === false && (
        <ThankYouSignUp
          isOpened={success === false}
          handleConfirm={()=>{setSuccess()}}
          onClose={() => setSuccess()}
          title={`Whoops! Our server had a misstep! ${signupError}`}
        />
      )}
    </div>
  );
};

export default Step2;
