import React from "react";

import { useSignUpStudentFirst } from "../../../../hooks";
import Error from "../../../../assets/error.svg";

import "./FirstForm.scss";
import classNames from "classnames";

const FirstForm = ({
  setPage,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  error,
  firstError,
  lastError,
  showError,
  setShowError,
  phone,
  setPhone,
  setCompleteAddress,
  setZipCode,
  age,
  setage,
}) => {
  const { city, zipCode, state, inputRef, address, setAddress, handleBack } =
    useSignUpStudentFirst(setCompleteAddress, setZipCode);
  return (
    <div className="FirstForm">
      <div className="FirstForm-cont">
        <div
          className={classNames("FirstForm-cont__1", {
            displayFlex: !firstError && lastError,
          })}
        >
          <div className="input">
            <p>First Name</p>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {firstError && showError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>Enter valid first name</p>
              </div>
            )}
          </div>
          <div className="input">
            <p>Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && showError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>{error}</p>
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames("FirstForm-cont__2", {
            displayFlex: firstError && !lastError,
          })}
        >
          <div className="input">
            <p>Last Name</p>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {lastError && showError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>Please enter valid last name</p>
              </div>
            )}
          </div>
          <div
            className={classNames("input", {
              marginBottom: error && firstError && showError,
            })}
          >
            <p>Phone</p>
            <input
              type="email"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "16px" }} className="FirstForm__tag">
        <p>Address</p>
        <input
          ref={inputRef}
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>

      <div className="FirstForm-cont">
        <div className="FirstForm-cont__1">
          <div className="input">
            <p>City</p>
            <input value={city} />
          </div>
          <div className="input">
            <p>Age</p>
            <select
              value={age}
              onChange={(e) => {
                setage(e.target.value);
              }}
            >
              {[...new Array(100)]
                .filter((each, index) => index >= 13)
                .map((each, index) => (
                  <option key={index} value={index + 13}>
                    {index + 13}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="FirstForm-cont__2">
          <div className="input">
            <p>State</p>
            <input value={state} />
          </div>
          <div className="input">
            <p>Zip Code</p>
            <input value={zipCode} />
          </div>
        </div>
      </div>

      <div className="FirstForm-navigation">
        <div>
          <button onClick={handleBack} className="FirstForm-navigation__back">
            Back
          </button>
          <button
            onClick={() => {
              // if (!error || firstError || lastError) {
              //   setPage(2)
              // } else {
              //   setShowError(true)
              // }

              if (error || !firstName || !lastName) {
                setShowError(true);
              } else {
                setPage(2);
              }
            }}
            className="FirstForm-navigation__continue"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstForm;
